import React from 'react';
import type { PageProps } from 'gatsby';

import PageLayout from '../../../views/Layout/Page';
import TripDetail from '../../../views/Trip/TripDetail';

export default function TripDetailPage({ params }: PageProps) {
  return (
    <PageLayout pageview="trip_detail" private requiredEnums={['fuel', 'type']}>
      <TripDetail hash={params.hash} />
    </PageLayout>
  );
}

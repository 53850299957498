import React from 'react';

import type { Trip } from '../../model/Trip';
import type { Driver, Owner } from '../../model/User';
import Tracking from '../../services/Tracking';
import T from '../Translate';
import UserLocket from '../User/UserLocket';

type Props = {
  isOwner: boolean;
  user: Driver | Owner;
  trip: Trip;
};

export default function UserInfo({ isOwner, user, trip }: Props) {
  return (
    <>
      <h3 className="mb-4 h4">
        <T id={isOwner ? 'global.driver' : 'global.owner'} />
      </h3>
      <UserLocket
        onClick={() => Tracking.track('TRIP_USER_PROFILE_CLICKED', trip, user, isOwner)}
        trip={trip}
        user={user}
      />
    </>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { canUserRateTrip, isTripPaid, type Trip, TripState } from '../../model/Trip';
import type { ReduxState } from '../../store';
import T from '../Translate';

type StepperProps = {
  step: number;
};

/**
 * Progress bar steps
 * @param step number from -1 to 6
 * -1:  error - empty - empty - empty
 *  0:  compl - empty - empty - empty
 *  1:  compl - activ - empty - empty
 *  2:  compl - compl - empty - empty
 *  3:  compl - compl - activ - empty
 *  4:  compl - compl - compl - empty
 *  5:  compl - compl - compl - activ
 *  6:  compl - compl - compl - compl
 */
const Stepper = ({ step }: StepperProps) => {
  return (
    <ul className="stepper">
      <li className={cn({ 'active error': step < 0, complete: step >= 0 })}>
        <i className={`icon ${step < 0 ? 'icon-round-remove' : 'icon-round-check'}`} />
        <T id="global.payment" />
      </li>
      <li className={cn({ active: step === 1, complete: step >= 2 })}>
        <i className={`icon ${step < 2 ? 'icon-round-empty' : 'icon-round-check'}`} />
        <T id="global.handover" />
      </li>
      <li className={cn({ active: step === 3, complete: step >= 4 })}>
        <i className={`icon ${step < 4 ? 'icon-round-empty' : 'icon-round-check'}`} />
        <T id="global.reservation.return" />
      </li>
      <li className={cn({ active: step === 5, complete: step >= 6 })}>
        <i className={`icon ${step < 5 ? 'icon-round-empty' : 'icon-round-check'}`} />
        <T id="global.ratings" />
      </li>
    </ul>
  );
};

type Props = {
  trip: Trip;
};

const ProgressBar = ({ trip }: Props) => {
  const user = useSelector((store: ReduxState) => store.user);

  if (!isTripPaid(trip)) {
    return <Stepper step={-1} />;
  }

  switch (trip.state) {
    case TripState.WAITING_FOR_HANDOVER:
      return <Stepper step={0} />;

    case TripState.HAND_IN:
      return <Stepper step={1} />;

    case TripState.IN_PROGRESS:
      return <Stepper step={2} />;

    case TripState.HAND_OUT:
      return <Stepper step={3} />;

    case TripState.WAITING_FOR_COMPLETION:
      return <Stepper step={4} />;

    case TripState.FINISHED:
      if (!canUserRateTrip(user, trip)) return null;
      return <Stepper step={4} />;

    default:
      return null;
  }
};

export default ProgressBar;

import React from 'react';
import qs from 'query-string';

import config from '../config';
import { useLanguage } from '../hooks/useLanguage';
import type { Coordinates } from '../model/Coordinates';
import type { Location } from '../model/Location';

type Props = {
  location: Location | Coordinates;
  zoom?: number;
  height?: number | string;
  width?: number | string;
};

export default function StaticMap(props: Props) {
  const language = useLanguage();
  const mapParams = {
    key: config.googleApiKey,
    q: `${props.location.lat},${props.location.lon}`,
    language,
    zoom: props.zoom,
  };

  const location = props.location as Location;
  if (location.street && location.city) {
    mapParams.q = `${location.street}, ${location.city}`;
  }

  return (
    <iframe
      allowFullScreen
      frameBorder="0"
      height={props.height}
      src={`https://www.google.com/maps/embed/v1/place?${qs.stringify(mapParams)}`}
      style={{ border: 0 }}
      title="Google Embedded Map"
      width={props.width}
    />
  );
}

StaticMap.defaultProps = {
  zoom: 14,
  height: 200,
  width: 652,
};

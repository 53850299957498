import React from 'react';

import { getNavigationLink, type VehicleParking } from '../../model/Location';
import Distance from '../Distance';
import StaticMap from '../StaticMap';
import T from '../Translate';

type Props = {
  location: VehicleParking | null;
  showNavigationButton?: boolean;
  onNavigateClick?(): void;
};

export default function LocationComponent({ location, showNavigationButton, onNavigateClick }: Props) {
  if (!location) {
    return null;
  }

  return (
    <div className="vehicle-location">
      <div className="vehicle-location__address">
        <div className="vehicle-location__address--icon">
          <i className="icon icon-pin" />
        </div>
        <div className="vehicle-location__address--main">
          <h3 className="vehicle-location--title">
            {location.street}, {location.city}
            <Distance location={location} showBull />
          </h3>
          {showNavigationButton && (
            <a
              className="btn btn-sm btn-light-primary px-3 ml-sm-3 mt-3 mt-sm-0"
              href={getNavigationLink(location)}
              onClick={onNavigateClick}
              rel="noopener noreferrer"
              target="_blank"
            >
              <T id="global.navigate" />
            </a>
          )}
        </div>
      </div>
      <div className="vehicle-location__map mt-4">
        <StaticMap location={location} width="100%" />
      </div>
    </div>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';

import { formatNumber } from '../helpers';
import { useLanguage } from '../hooks/useLanguage';
import type { Coordinates } from '../model/Coordinates';
import type { Location } from '../model/Location';
import { getDistanceFromGPSInKm } from '../services/Distance';
import type { ReduxState } from '../store';

type Props = {
  location?: Location | Coordinates;
  distance?: number;
  showBull?: boolean;
};

export default function Distance({ location, distance, showBull }: Props) {
  const filters = useSelector((state: ReduxState) => state.rentCar.filters);
  const language = useLanguage();
  const { lat, lon } = filters.location;

  if (typeof lat !== 'number' || typeof lon !== 'number') {
    return null;
  }

  if (typeof distance !== 'number' && typeof location.lat === 'number' && typeof location.lon === 'number') {
    distance = getDistanceFromGPSInKm({ lat, lon }, location);
  }

  return (
    <>
      {showBull && <span className="text-muted mr-1">&bull;</span>}
      {formatNumber(distance, language, [0, 1])}&nbsp;km
    </>
  );
}

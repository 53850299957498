import React from 'react';
import cn from 'classnames';

import avatarImage from '../../assets/images/avatar.svg';
import type { Document } from '../../model/Document';
import Image, { type ImageAttributes } from '../Image';

import styles from './avatar.module.scss';

interface Props extends ImageAttributes {
  image?: Document | string;
}

export default function Avatar({ image, ...props }: Props) {
  return <Image alt="avatar" src={image ?? avatarImage} {...props} className={cn(styles.avatar, props.className)} />;
}

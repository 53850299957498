import React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import { TripType } from '../../model/Trip';
import { generateCarDetailLinkParams } from '../../model/Vehicle';
import RouteEnum from '../../RouteEnum';
import { toISOString } from '../../services/DateTime';
import type { ReduxState } from '../../store';
import Link, { type LinkProps } from '../Link';

export interface CarDetailLinkProps<T> extends Partial<Omit<LinkProps<T>, 'to' | 'params'>> {
  hash: string;
  manufacturer?: string;
  model?: string;
  fuel?: string;
  bodyType?: string;
  children: React.ReactNode;
  className?: string;
}

export default function CarDetailLink<T>({
  hash,
  manufacturer,
  model,
  fuel,
  bodyType,
  ...props
}: CarDetailLinkProps<T>) {
  const { filters } = useSelector((state: ReduxState) => ({
    filters: state.rentCar.filters,
  }));

  const query = useMemo(() => {
    if (!filters) return null;
    const query = {
      date_from: toISOString(filters.date_from),
      date_to: toISOString(filters.date_to),
      trip_type: filters.trip_type !== TripType.BUSINESS ? filters.trip_type : undefined,
    };
    const qs = queryString.stringify(query);
    return qs.length ? `?${qs}` : '';
  }, [filters]);

  const params = generateCarDetailLinkParams(manufacturer, model, bodyType, fuel);
  return (
    <Link {...props} params={{ params, hash }} search={query} to={RouteEnum.CAR_DETAIL}>
      {props.children}
    </Link>
  );
}

import React from 'react';

import {
  isTripPaid,
  isTripReadyForHandover,
  isTripWaitingForHandover,
  isTripWaitingForReturn,
  type Trip,
} from '../../model/Trip';
import MobileAppLinks from '../MobileAppLinks';
import T from '../Translate';

import styles from './handoverInAppNotice.module.scss';

type Props = {
  trip: Trip;
};

export default function TripActions(props: Props) {
  // Dokud není zaplaceno tak neumožnit žádný přechod dále
  if (!isTripPaid(props.trip)) {
    return null;
  }

  if (isTripWaitingForHandover(props.trip)) {
    if (!isTripReadyForHandover(props.trip)) {
      return null;
    }
    return <HandoverInAppNotice />;
  }

  if (isTripWaitingForReturn(props.trip)) {
    return <HandoverInAppNotice />;
  }

  return null;
}

function HandoverInAppNotice() {
  return (
    <div className={styles.tripDetailContainer}>
      <T as="h4" className={styles.header} id="global.handoverNotice.title" />
      <T as="p" id="global.handoverNotice.description" />
      <MobileAppLinks className={styles.mobileApps} detectSystem height={40} />
    </div>
  );
}

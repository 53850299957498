import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import type { Trip } from '../../model/Trip';
import {
  type BraintreeClientToken,
  fetchBrainTreeClientToken,
  formatPaymentMethod,
  getBraintreeDeviceData,
  getBraintreePaymentMethods,
} from '../../services/Braintree';
import { type Country, countryToRegion } from '../../services/Country';
import { flashMessageError } from '../../store/session';
import Loader from '../Loader';
import Price from '../Price';
import T from '../Translate';

type Props = {
  trip: Trip;
  onPayment(nonce: string, deviceData?: string): void;
};

const Payment = ({ onPayment, trip }: Props) => {
  const dispatch = useDispatch();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPaymentMethodNonce, setSelectedPaymentMethodNonce] = useState(null);
  const deviceData = useRef<string>(null);

  const getPaymentMethods = useCallback(
    async (country: Country) => {
      try {
        const brainTreeClientToken: BraintreeClientToken = await fetchBrainTreeClientToken(country);
        const data = await getBraintreeDeviceData(brainTreeClientToken.client_token);
        if (data) {
          deviceData.current = data;
        }
        const paymentMethods = await getBraintreePaymentMethods(brainTreeClientToken.client_token);
        if (paymentMethods.length === 0) {
          return dispatch(flashMessageError('flash.noPaymentMethods'));
        }

        setPaymentMethods(paymentMethods);
        setSelectedPaymentMethodNonce(paymentMethods[0].nonce);
        setIsLoading(false);
      } catch (err) {
        dispatch(flashMessageError(err.message));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    getPaymentMethods(countryToRegion(trip.vehicle.country.alpha2_code));
  }, [getPaymentMethods, trip]);

  const submit = e => {
    e.preventDefault();
    setIsLoading(true);
    onPayment(selectedPaymentMethodNonce, deviceData.current);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <h3 className="mb-4 h4">
            <T id="tripDetail.handoverInfo" />
          </h3>
        </div>
        <div className="col text-right">
          <span className="display-3 text-primary">
            <Price price={trip.price_total} />
          </span>
        </div>
      </div>
      <span className="text-muted font-size-sm">
        <T id="global.creditCard" />
      </span>
      <form className="form-inline mb-2">
        <div className="form-group">
          <label className="sr-only" htmlFor="shareCode">
            <T id="global.creditCard" />
          </label>
          <select
            className="custom-select"
            onChange={e => setSelectedPaymentMethodNonce(e.target.value)}
            value={selectedPaymentMethodNonce}
          >
            {paymentMethods.map(paymentMethod => (
              <option key={paymentMethod.nonce} value={paymentMethod.nonce}>
                {formatPaymentMethod(paymentMethod)}
              </option>
            ))}
          </select>
        </div>
        <button className="btn btn-primary ml-3" onClick={submit}>
          <T id="global.pay" />
        </button>
      </form>
    </>
  );
};

export default Payment;

import React from 'react';
import { useDispatch } from 'react-redux';

import { type Trip, TripState } from '../../model/Trip';
import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import Tracking from '../../services/Tracking';
import { confirmTripRequest, declineTripRequest } from '../../store/cars';
import { flashMessageError } from '../../store/session';
import T from '../Translate';

type Props = {
  trip: Trip;
  isOwner?: boolean;
};

export default function TripDetailActions({ trip, isOwner = false }: Props) {
  const dispatch = useDispatch();
  const { navigate, generatePath } = useRouteContext();
  const confirm = async () => {
    try {
      await dispatch(confirmTripRequest(trip.hash));
      Tracking.track('TRIP_REQUEST_ACCEPTED', trip);
      navigate(
        generatePath(RouteEnum.CARS, {
          hash: trip.vehicle.hash,
          tab: 'reservation-requests',
        }),
      );
    } catch (err) {
      dispatch(flashMessageError(err.message));
    }
  };

  const cancel = async () => {
    try {
      await dispatch(declineTripRequest(trip.hash));
      Tracking.track('TRIP_REQUEST_DECLINED', trip);
      navigate(
        generatePath(RouteEnum.CARS, {
          hash: trip.vehicle.hash,
          tab: 'reservation-requests',
        }),
      );
    } catch (err) {
      dispatch(flashMessageError(err.message));
    }
  };

  switch (trip.state) {
    case TripState.REQUEST:
      return (
        <>
          {isOwner && (
            <div className="column align-items-stretch mt-4" data-column-size="xs">
              <div className="col-sm">
                <button className="btn btn-primary btn-block mb-2" onClick={confirm}>
                  <T id="global.accept" />
                </button>
              </div>
              <div className="col-sm">
                <button className="btn btn-light-danger btn-block" onClick={cancel}>
                  <T id="global.refuse" />
                </button>
              </div>
            </div>
          )}
        </>
      );
    default:
      return null;
  }
}

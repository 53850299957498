import React from 'react';
import { useRef } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { v4 as uuid } from 'uuid';

import T, { useTranslation } from '../Translate';

import Field, { type FieldAttributes } from './Field';
import { ValidationMessage } from './ValidationMessage';

export interface FormGroupSharedProps {
  id?: string;
  className?: string;
  label?: string;
  labelSrOnly?: boolean;
  labelClassName?: string;
  labelData?: Record<string, unknown>;
  hint?: string;
  hintData?: Record<string, unknown>;
  hideValidation?: boolean;
  error?: string;
}

export interface FormGroupProps extends FormGroupSharedProps {
  id?: string;
  children: React.ReactNode;
}

export default function FormGroup(props: FormGroupProps) {
  return (
    <div className={classNames('form-group', props.className)}>
      {props.label ? (
        <label className={classNames({ 'sr-only': props.labelSrOnly }, props.labelClassName)} htmlFor={props.id}>
          <T data={props.labelData} id={props.label} optional />
        </label>
      ) : null}
      {props.children}
      {props.hint ? (
        <small className="form-text text-muted">
          <T data={props.hintData} id={props.hint} optional />
        </small>
      ) : null}
      {!props.hideValidation && <ValidationMessage message={props.error} show={typeof props.error === 'string'} />}
    </div>
  );
}

export interface FormikFormGroupProps extends FormGroupProps {
  name: string;
}

export function FormikFormGroup(props: FormikFormGroupProps) {
  const [, meta] = useField(props.name);
  const show = meta.error && (meta.touched || typeof meta.initialValue === 'boolean');
  return <FormGroup {...props} error={props.error ?? (show ? meta.error : null)} />;
}

export interface FormGroupFieldProps extends FormGroupSharedProps, FieldAttributes<UnsafeAny> {
  name: string;
  fieldClassName?: string;
}

export function FormGroupField({
  label,
  labelSrOnly,
  labelClassName,
  labelData,
  className,
  fieldClassName,
  hint,
  hintData,
  hideValidation,
  error,
  min,
  max,
  ...props
}: FormGroupFieldProps) {
  const [field, meta] = useField(props);
  const id = useRef(props.id ?? uuid());
  const { t } = useTranslation();

  if (
    !hint &&
    !error &&
    (min !== undefined || max !== undefined) &&
    (props.type === 'text' || props.as === 'textarea')
  ) {
    if (max !== undefined) {
      max = typeof max === 'number' ? max : Number.parseInt(max, 10);
      const charsLeft = max - field.value.length;
      if (!charsLeft) {
        hint = null;
        error = null;
      } else if (charsLeft > 0) {
        hint = t('global.textarea.charactersLeftText', { count: charsLeft });
      } else {
        hint = null;
        error = t('global.textarea.charactersMax', { count: -charsLeft });
      }
    }
    if (min !== undefined) {
      min = typeof min === 'number' ? min : Number.parseInt(min, 10);
      const translation = t('global.textarea.charactersMin', {
        count: min - field.value.length,
      });
      if (!field.value.length) {
        hint = !meta.touched ? translation : null;
        error = meta.touched ? t('global.required') : null;
      } else if (field.value.length < min) {
        hint = translation;
        error = null;
        hideValidation = true;
      }
    }
  }

  return (
    <FormikFormGroup
      className={className}
      error={error}
      hideValidation={hideValidation}
      hint={hint}
      hintData={hintData}
      id={id.current}
      label={label}
      labelClassName={labelClassName}
      labelData={labelData}
      labelSrOnly={labelSrOnly}
      name={props.name}
    >
      <Field {...props} className={fieldClassName} id={id.current} />
    </FormikFormGroup>
  );
}

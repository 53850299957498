import React from 'react';
import classNames from 'classnames';

import { range } from '../../helpers';

type Props = {
  rating: number;
  className?: string;
  onClick?: (star: number) => void;
};

function StarRating({ rating = 0, className, onClick }: Props) {
  return (
    <>
      {range(0, 5).map(index => (
        <i
          className={classNames(
            'icon',
            index >= Math.floor(rating) && index < rating ? 'icon-star-half' : 'icon-star',
            { active: index < rating },
            className,
          )}
          key={index}
          onClick={() => onClick?.(index + 1)}
        />
      ))}
    </>
  );
}

export default StarRating;

import React from 'react';
import { useSelector } from 'react-redux';

import { type CurrencyCode, formatPrice, type PriceType } from '../model/Price';
import type { ReduxState } from '../store';

import T from './Translate';

export interface SharedPriceProps {
  as?: string;
  className?: string;
  fractions?: number | [number, number];
  unit?: string;
  negative?: boolean;
}

interface Props extends SharedPriceProps {
  amount: number;
  currency?: CurrencyCode;
}

interface Props2 extends SharedPriceProps {
  price?: PriceType | undefined;
}

const isPriceType = (object: UnsafeAny | Props2): object is Props2 => typeof object.price === 'object';

function Price(props: Props);
function Price(props: Props2);
function Price(props: Props | Props2) {
  const lang = useSelector((store: ReduxState) => store.app.language);
  let Component = props.as ?? React.Fragment;
  const componentProps: React.HTMLAttributes<UnsafeAny> = {};
  if (props.className) {
    if (!props.as) {
      Component = 'span';
    }
    componentProps.className = props.className;
  }

  let amount = isPriceType(props) ? props.price?.amount : props.amount;
  if (props.negative) {
    amount = -amount;
  }
  const currency = isPriceType(props) ? props.price?.currency : props.currency;

  return (
    <Component {...componentProps}>
      {formatPrice(amount, lang, currency, props.fractions)}
      {props.unit ? <T className="ml-1" id={props.unit} optional /> : null}
    </Component>
  );
}

export default Price;

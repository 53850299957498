import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

import { isTripCancelled, type Trip } from '../../model/Trip';
import { isBeforeNow } from '../../services/DateTime';
import Tracking from '../../services/Tracking';
import { cancelTripDetail } from '../../store/tripDetail';
import { FormGroupField } from '../Form/FormGroup';
import FormikSubmitButton from '../Form/SubmitButton';
import T from '../Translate';

interface Props {
  trip: Trip;
  isOwner: boolean;
}

export default function CancelReason(props: Props) {
  const dispatch = useDispatch();
  const canCancel = useMemo(() => props.trip.free_cancellation_time !== null, [props.trip]);
  const [showInput, setShowInput] = useState(false);
  const formik = useFormik({
    validationSchema: object({
      reason: string().required('global.required').min(50, 'tripDetail.cancelReason.lengthText'),
    }),
    initialValues: {
      reason: '',
    },
    async onSubmit({ reason }) {
      if (!canCancel) return;
      Tracking.track('TRIP_CANCEL_SUBMITTED', props.trip);
      await dispatch(cancelTripDetail(reason));
    },
  });

  const handleClick = () => {
    setShowInput(true);
    Tracking.track('TRIP_CANCEL_CLICKED', props.trip);
  };

  if (!props.trip || isBeforeNow(props.trip.date_from) || isTripCancelled(props.trip)) {
    return null;
  }

  if (!showInput) {
    return (
      <div className="text-center my-5">
        <button className="btn btn-link text-muted" onClick={handleClick} type="button">
          <T id="tripDetail.cancelReason.cancel" />
        </button>
      </div>
    );
  }

  if (!canCancel) {
    return (
      <div className="text-center my-5">
        <div className="alert alert-primary">
          <T context={props.isOwner ? 'owner' : 'driver'} id="trip.detail.cancel.callCustomerCare" />
        </div>
      </div>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form className="my-5">
        <FormGroupField as="textarea" name="reason" placeholder="tripDetail.cancelReason.reason" />

        <div className="d-flex justify-content-center">
          <FormikSubmitButton>
            <T id="tripDetail.cancelReason.cancel" />
          </FormikSubmitButton>
        </div>
      </Form>
    </FormikProvider>
  );
}

import type { Coordinates } from '../model/Coordinates';

const R = 6371; // Radius of the earth in km

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function getDistanceFromGPSInKm(locationFrom: Coordinates, locationTo: Coordinates) {
  const dLat = deg2rad(locationTo.lat - locationFrom.lat);
  const dLon = deg2rad(locationTo.lon - locationFrom.lon);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(locationFrom.lat)) * Math.cos(deg2rad(locationTo.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

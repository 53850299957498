import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isOwner, isTripPaid, type Trip, TripState } from '../../model/Trip';
import { VehicleState } from '../../model/Vehicle';
import { getVehicleParking } from '../../model/VehicleLocation';
import RouteEnum from '../../RouteEnum';
import { formatDatelike } from '../../services/DateTime';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { loadTripDetail, payTripDetail } from '../../store/tripDetail';
import Image from '../Image';
import Link from '../Link';
import Loader from '../Loader';
import MetaTags from '../MetaTags';
import { RestrictionProvider } from '../Restriction';
import T from '../Translate';
import CarDetailLink from '../Vehicle/CarDetailLink';
import LocationComponent from '../Vehicle/Location';

import CancelReason from './CancelReason';
import HandoverStartsAt from './HandoverStartsAt';
import Payment from './Payment';
import { DriverPricingBreakdown, OwnerPricingBreakdown } from './PricingBreakdown';
import TripActions from './TripActions';
import TripDetailActions from './TripDetailActions';
import ProgressBar from './TripDetailProgressBar';
import Status from './TripDetailStatus';
import UserInfo from './UserInfo';

import styles from './tripDetail.module.scss';

interface Props {
  hash: string;
}

export default function TripDetail({ hash }: Props) {
  const dispatch = useDispatch();
  const { trip, loading, isTripOwner } = useSelector((state: ReduxState) => ({
    trip: state.tripDetail.data,
    loading: state.tripDetail.loading,
    isTripOwner: state.tripDetail.loading ? false : isOwner(state.user, state.tripDetail.data),
  }));

  const handlePayTrip = async (nonce: string, deviceData?: string) => {
    dispatch(payTripDetail(nonce, deviceData));
  };

  useEffect(() => {
    dispatch(loadTripDetail(hash));
  }, [hash]);

  if (loading) {
    return (
      <main className="main-container container container--top">
        <MetaTags />
        <Loader className="my-5 mx-auto" />
      </main>
    );
  }

  const location = getVehicleParking(trip.vehicle.locations);

  const showHandInLink = [
    TripState.IN_PROGRESS,
    TripState.HAND_OUT,
    TripState.WAITING_FOR_COMPLETION,
    TripState.FINISHED,
  ].includes(trip.state);
  const showHandOutLink = [TripState.WAITING_FOR_COMPLETION, TripState.FINISHED].includes(trip.state);

  return (
    <main className="main-container container--top">
      <MetaTags />
      <RestrictionProvider region={trip.vehicle.country.alpha2_code}>
        <div className={styles.header}>
          <Status isOwner={isTripOwner} trip={trip} />
          <HandoverStartsAt isOwner={isTripOwner} trip={trip} />
          <ProgressBar trip={trip} />
          <TripActions trip={trip} />
        </div>

        <div className={styles.container}>
          <TripCarDetail vehicle={trip.vehicle} />
          <div className={styles.content}>
            <div className="box">
              {!isTripOwner && !isTripPaid(trip) && (
                <>
                  <Payment onPayment={handlePayTrip} trip={trip} />
                  <hr />
                </>
              )}

              {showHandInLink && (
                <Link
                  className="btn btn-link btn-link--left btn-link--arrow btn-block mb-2"
                  params={{ hash: trip.hash }}
                  to={RouteEnum.TRIP_DETAIL_HAND_IN}
                >
                  <i className="icon icon-note mr-2" />
                  <T as="span" id="tripDetail.handInDetails.link" />
                  <i className="icon icon-arrow-right text-muted" />
                </Link>
              )}
              {showHandOutLink && (
                <Link
                  className="btn btn-link btn-link--left btn-link--arrow btn-block mb-2"
                  params={{ hash: trip.hash }}
                  to={RouteEnum.TRIP_DETAIL_HAND_OUT}
                >
                  <i className="icon icon-note mr-2" />
                  <T as="span" id="tripDetail.handOutDetails.link" />
                  <i className="icon icon-arrow-right text-muted" />
                </Link>
              )}

              {showHandInLink || showHandOutLink ? <hr /> : null}

              {location ? (
                <>
                  <h3 className="mb-4 h4">
                    <T id="global.vehicleAddress" />
                  </h3>
                  <LocationComponent
                    location={location}
                    onNavigateClick={() => Tracking.track('TRIP_NAVIGATION_CLICKED', isTripOwner)}
                    showNavigationButton
                  />
                  <hr />
                </>
              ) : null}

              <UserInfo isOwner={isTripOwner} trip={trip} user={isTripOwner ? trip.driver : trip.owner} />
              <hr />

              <h3 className="mb-4 h4">
                <T id="tripDetail.dateOfRide" />
              </h3>
              <div className="range mb-4">
                <div className="range__from">
                  <div className="range--name">
                    <T id="global.reservation.pickup" />
                  </div>
                  <div className="range__info">
                    <div className="range__info--date with-arrow">
                      <span className="with-arrow--bg">{formatDatelike(trip.date_from, 'd. M.')}</span>
                    </div>
                    <div className="range__info--hour">{formatDatelike(trip.date_from, 'H:mm')}</div>
                  </div>
                </div>
                <div className="range__to">
                  <div className="range--name">
                    <T id="global.reservation.return" />
                  </div>
                  <div className="range__info">
                    <div className="range__info--date">{formatDatelike(trip.date_to, 'd. M.')}</div>
                    <div className="range__info--hour">{formatDatelike(trip.date_to, 'H:mm')}</div>
                  </div>
                </div>
              </div>

              <hr />
              <T as="h4" className="mb-4" id="global.price" />

              {isTripOwner ? (
                <OwnerPricingBreakdown pricingBreakdown={trip.pricing_breakdown} />
              ) : (
                <DriverPricingBreakdown
                  mileage={trip.total_mileage}
                  pricePerDay={trip.pricing.price_per_day}
                  pricePerKm={trip.vehicle.prices.price_per_km}
                  pricingBreakdown={trip.pricing_breakdown}
                  tripDays={trip.trip_length}
                  tripPrice={trip.pricing.trip_price}
                  // longTermDiscountPercent={trip.percentage_long_term_discount}
                  // longTermDiscount={trip.long_term_discount}
                />
              )}

              <TripDetailActions isOwner={isTripOwner} trip={trip} />
            </div>

            <CancelReason isOwner={isTripOwner} trip={trip} />
          </div>
        </div>
      </RestrictionProvider>
    </main>
  );
}

function TripCarDetail({ vehicle }: { vehicle: Trip['vehicle'] }) {
  if (vehicle.state === VehicleState.DELETED) {
    return (
      <div className={styles.vehicleInfo}>
        <Image src={vehicle.main_image} />
        <h3>{vehicle.name}</h3>
      </div>
    );
  }

  return (
    <CarDetailLink
      bodyType={vehicle.type}
      className={styles.vehicleInfo}
      fuel={vehicle.fuel}
      hash={vehicle.hash}
      manufacturer={vehicle.manufacturer}
      model={vehicle.model}
    >
      <Image src={vehicle.main_image} />
      <h3>{vehicle.name}</h3>
    </CarDetailLink>
  );
}

import React from 'react';

import { isTripWaitingForHandover, type Trip } from '../../model/Trip';
import { formatDatelike } from '../../services/DateTime';
import T from '../Translate';

type Props = {
  trip: Trip;
  isOwner: boolean;
};

export default function HandoverStartsAt({ isOwner, trip }: Props) {
  if (!isTripWaitingForHandover(trip)) return null;

  return (
    <div className="text-center mb-5">
      <T
        as="h3"
        data={{
          date: formatDatelike(trip.date_from, 'd. MMMM'),
          time: formatDatelike(trip.date_from, 'H:mm'),
        }}
        id="tripDetail.header.handoverAt"
      />
      <T as="p" className="text-muted" html id={`tripDetail.header.dontCareText${isOwner ? '.owner' : ''}`} />
    </div>
  );
}

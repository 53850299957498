import React from 'react';
import cn from 'classnames';

import { useLanguage } from '../../hooks/useLanguage';
import { formatPrice, type PriceType } from '../../model/Price';
import type { TripPricingBreakdown } from '../../model/TripPricingBreakdown';
import Loader from '../Loader';
import NumberFormat from '../NumberFormat';
import Price from '../Price';
import T from '../Translate';

import styles from './pricingBreakdown.module.scss';

interface SharedProps {
  pricingBreakdown: TripPricingBreakdown;
  className?: string;
}

interface Props extends SharedProps {
  loading?: boolean;
  tripPrice: PriceType;
  tripDays: number;
  pricePerDay: PriceType;
  mileage: number;
  pricePerKm: PriceType;
  longTermDiscountPercent?: number;
  longTermDiscount?: PriceType;
  children?: React.ReactNode;
}

export function DriverPricingBreakdown(props: Props) {
  const lang = useLanguage();
  return (
    <div className={cn(styles.container, props.className)}>
      <section>
        <h4>
          <T as="span" id="booking.priceDetail.trip.title" />
          <span>{props.loading ? <Loader /> : <Price price={props.tripPrice} />}</span>
        </h4>
        <div className={styles.dotLine}>
          <i className="icon icon-round-check text-muted" />
          <T
            data={{
              count: props.tripDays,
              tripDays: props.tripDays,
              pricePerDay: formatPrice(props.pricePerDay, lang),
            }}
            html
            id="booking.priceDetail.trip.pricePerDay"
          />
        </div>
        <div className={styles.dotLine}>
          <i className="icon icon-round-check text-muted" />
          <T data={{ mileage: props.mileage }} html id="booking.priceDetail.trip.mileage" />
        </div>
        <div className={styles.dotLine}>
          <i className="icon icon-round-check text-muted" />
          <T data={{ pricePerKm: formatPrice(props.pricePerKm, lang) }} html id="booking.priceDetail.trip.pricePerKm" />
        </div>
        <div className={styles.dotLine}>
          <i className="icon icon-round-check text-muted" />
          <T html id="booking.priceDetail.trip.fuel" />
        </div>
      </section>

      {props.longTermDiscount?.amount ? (
        <section>
          <h4>
            <T as="span" id="flexibleDiscounts.title" />
            <span>
              {props.loading ? <Loader /> : <Price className={styles.discount} price={props.longTermDiscount} />}
            </span>
          </h4>
          <div className={styles.priceLine}>
            <NumberFormat fractions={[0, 1]} suffix="%" value={props.longTermDiscountPercent} />
          </div>
        </section>
      ) : null}

      {props.pricingBreakdown.map(section => (
        <section key={section.title}>
          <h4>
            <span>{section.title}</span>
            <span>{section.price ? <Price price={section.price} /> : null}</span>
          </h4>
          {section.items.map(item => (
            <div className={styles.priceLine} key={item.title}>
              <span>{item.title}</span>
              <span>{item.price ? <Price price={item.price} /> : null}</span>
            </div>
          ))}
        </section>
      ))}

      {props.children}
    </div>
  );
}

export function OwnerPricingBreakdown(props: SharedProps) {
  return (
    <div className={cn(styles.container, props.className)}>
      {props.pricingBreakdown.map(section => (
        <section key={section.title}>
          <h4>
            <span>{section.title}</span>
            <span>{section.price ? <Price price={section.price} /> : null}</span>
          </h4>
          {section.items.map(item => (
            <div className={styles.priceLine} key={item.title}>
              <span>{item.title}</span>
              <span>{item.price ? <Price price={item.price} /> : null}</span>
            </div>
          ))}
        </section>
      ))}
    </div>
  );
}

import React from 'react';

import { type Trip, TripState } from '../../model/Trip';
import { timeTill } from '../../services/DateTime';
import T from '../Translate';

import styles from './tripDetailStatus.module.scss';

interface Props {
  trip: Trip;
  isOwner: boolean;
}

const tripReservationAbortKeys = {
  driver: 'trip.status.reservationCancelledByDriver',
  owner: 'trip.status.reservationCancelledByOwner',
  admin: 'trip.status.reservationCancelledBySystem',
};

const tripAbortKeys = {
  driver: 'trip.status.tripCancelledByDriver',
  owner: 'trip.status.tripCancelledByOwner',
  admin: 'trip.status.tripCancelledByAdmin',
};

export default function TripDetailStatus({ isOwner, trip }: Props) {
  const ctx = isOwner ? 'owner' : 'driver';
  const [hoursTillConfirm, minutesTillConfirm] = timeTill(trip.confirm_to);

  switch (trip.state) {
    case TripState.REQUEST:
      return (
        <div className={styles.container}>
          <i className="icon icon-clock" />
          <div>
            <T as="h1" context={ctx} id="trip.status.request" />
            {hoursTillConfirm > 0 ? (
              <T
                as="p"
                context={ctx}
                data={{
                  count: hoursTillConfirm,
                  remainingHours: hoursTillConfirm,
                }}
                id="trip.status.request.remainingResponseHours"
              />
            ) : (
              <T
                as="p"
                context={ctx}
                data={{
                  count: minutesTillConfirm,
                  remainingMinutes: minutesTillConfirm,
                }}
                id="trip.status.request.remainingResponseMinutes"
              />
            )}
          </div>
        </div>
      );

    case TripState.EXPIRED:
    case TripState.CANCELLED_BY_SYSTEM:
    case TripState.CANCELLED_DUE_PAYMENT:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-remove" />
          <T as="h1" context={ctx} id="trip.status.reservationCancelledBySystem" />
        </div>
      );

    case TripState.DECLINED:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-remove" />
          <T as="h1" context={ctx} id="trip.status.reservationCancelledByOwner" />
        </div>
      );

    case TripState.CANCELLED:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-remove" />
          <T as="h1" context={ctx} id={tripReservationAbortKeys[trip.aborted_by_user]} />
        </div>
      );

    case TripState.CANCELLED_IN_PROGRESS:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-remove" />
          <T as="h1" context={ctx} id={tripAbortKeys[trip.aborted_by_user]} />
        </div>
      );

    case TripState.WAITING_FOR_HANDOVER:
      if (!trip.paid) {
        return (
          <div className={styles.container}>
            <i className="icon icon-round-warning" />
            <div>
              <T as="h1" context={ctx} id="trip.status.confirmedWithPaymentIssue" />
              <T as="p" context={ctx} id="trip.status.confirmedWithPaymentIssue.description" />
            </div>
          </div>
        );
      }
      return (
        <div className={styles.container}>
          <i className="icon icon-round-check" />
          <T as="h1" context={ctx} id="trip.status.confirmed" />
        </div>
      );

    case TripState.HAND_IN:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-check" />
          <T as="h1" context={ctx} id="trip.status.handinInProgress" />
        </div>
      );

    case TripState.IN_PROGRESS:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-check" />
          <T as="h1" context={ctx} id="trip.status.tripInProgress" />
        </div>
      );

    case TripState.HAND_OUT:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-check" />
          <T as="h1" context={ctx} id="trip.status.handoutInProgress" />
        </div>
      );

    case TripState.WAITING_FOR_COMPLETION:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-check" />
          <div>
            <T as="h1" context={ctx} id="trip.status.waitingForCompletion" />
            <T as="p" context={ctx} id="trip.status.waitingForCompletion.description" />
          </div>
        </div>
      );

    case TripState.FINISHED:
      return (
        <div className={styles.container}>
          <i className="icon icon-round-check" />
          <div>
            <T as="h1" context={ctx} id="trip.status.finished" />
            <T as="p" context={ctx} id="trip.status.finished.description" />
          </div>
        </div>
      );
  }

  return null;
}

import { getDistanceFromGPSInKm } from '../services/Distance';

import type { Coordinates } from './Coordinates';
import { type Location, LocationType, type VehicleParking } from './Location';
import type { Vehicle } from './Vehicle';

export function getVehicleParking(locations: (Location | VehicleParking)[]): VehicleParking {
  if (!locations) return null;
  return locations.find(l => l.type === LocationType.PARKING) as VehicleParking;
}

export function getVehicleDistanceFromGPS(vehicle: Vehicle, location: Location | Coordinates): number {
  if (typeof vehicle.distance === 'number') return vehicle.distance;
  if (typeof location?.lat !== 'number' || typeof location?.lon !== 'number') return null;
  const parking = getVehicleParking(vehicle.locations);
  if (!parking) return null;
  return getDistanceFromGPSInKm(parking, location);
}
